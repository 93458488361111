import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
		meta: { public: true }
  },
	{
		path: "/validar/:id?",
		name: "UserShow",
		component: () => import(/* webpackChunkName: "UserShow" */ "../views/Show.vue"),
		meta: { libre: true },
	},
  {
    path: '/dashboard',
    name: 'Dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		meta: { member: true },
	},
	{
		path: "/user/edit",
		name: "UserEdit",
		component: () => import(/* webpackChunkName: "UserEdit" */ "../views/Perfil.vue"),
		meta: { member: true },
	},
	{
		path: "/user/certificado",
		name: "UserCertificado",
		component: () => import(/* webpackChunkName: "UserCertificado" */ "../views/Certificado.vue"),
		meta: { member: true },
	},


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	store.dispatch("userModule/autoLogin");

	if (to.matched.some(record => record.meta.libre)) {
		next();
	}
	else if (to.matched.some(record => record.meta.public)) {

		if (store.state.userModule.user && store.state.userModule.user.role === 'MIEMBRO') {
			next({ name: "Dashboard" });
		} else {
			next();
		}

	} else if (store.state.userModule.user && store.state.userModule.user.role === 'MIEMBRO') {
		if (to.matched.some((record) => record.meta.member)) {
			next();
		}
	} else {
		next({ name: 'Home' });
	}
});

export default router
