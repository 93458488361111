import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
    themes: {
      light: {
        primary: "#0097DF",
        secondary: "#0057D3",
        accent: "#BDBDBD",
        info: "#00BFA5",
        error: "#FF5252",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
