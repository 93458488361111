<template>
	<v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline" dark>
          RECUPERAR ACCESO
        </v-card-title>
        <v-card-text>
					<h3>Por favor, Escriba su email registrado:</h3>
					<div v-if="!emailForgotSent">
						<v-form ref="formForgot" v-model="formForgotValidity">
							<v-text-field
								prepend-icon="mdi-email-outline"
								label="Correo electrónico"
								v-model.trim="emailForgot"
								:rules="[rules.required, rules.email]"
							/>

							<v-btn
								:loading="sending"
								@click.stop="sendNewPassword()"
								:disabled="!formForgotValidity"
								rounded
								color="primary accent-3">
								ENVIAR
							</v-btn>
						</v-form>
					</div>
					<div v-else>
						<v-alert dense text type="success" class="mt-4">
							 <strong>NUEVA CONTRASEÑA</strong> enviada a su correo electrónico!
						</v-alert>
					</div>
				</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
						x-large
						text
            color="accent darken-1 white--text"
						@click="closeDialogForgotEmail"
          >
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'forgotPassword',
		props: [
			'dialog'
		],
		data: ()=>({
			sending: false,
			emailForgot: '',
			formForgotValidity: false,
			emailForgotSent: false,
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalido email.'
				},
				password: value => value.length >= 6 || 'Contraseña obligatoria',
			},
		}),
		methods: {
			sendNewPassword(){
				this.sending = true;
				let me = this;
				me.emailForgot = me.emailForgot.trim().toLowerCase()
				axios.post('user/forgot',{
					email: me.emailForgot
				})
					.then( response => { return response.data })
					.then(data =>{

						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Contraseña enviada con éxito a su correo electrónico',
							timeout: 4000
						}, {root: true})
						me.sending = false;
						me.closeDialogForgotEmail();

          })
					.catch( function(error){
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'error',
							message: 'Lo sentimos, regitro no existe',
							timeout: 4000
						}, {root: true})
						//console.log('>>> '+ error)
						me.sending = false

					});
				console.log('contrasena enviada')
			},
			closeDialogForgotEmail() {
				this.emailForgotSent = false
				this.emailForgot = ''
				this.formForgotValidity = false
				this.$refs.formForgot.reset()
				this.$refs.formForgot.resetValidation()
				this.$emit('dialogForgotShow', false);
				this.dialogShow = false;
			},
		},
		computed: {

		}

	}
</script>

<style lang="scss" scoped>

</style>