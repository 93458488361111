<template>
  <v-app class="grey lighten-2">
    <ToolbarUser />

		<v-main>
			<slot />
		</v-main>

		<Footer />
		<v-snackbar
			shaped
			top
			centered
			:timeout="alert.timeout"
			:color="alert.color"
			v-model="alert.show">
				{{ alert.message }}
		</v-snackbar>


  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {
    Footer: () => import('@/components/core/Footer.vue'),
    ToolbarUser: () => import('@/components/core/ToolbarUser.vue')
	},
	data: ()=>({
	}),
	mounted(){
	},
  computed: {
		...mapState(['alert']),
    isHome() {
      return this.$route.path === "/"
		},

		isAdmin() {
			return this.$store.state.userModule.user && this.$store.state.userModule.user.role == 'STAFF'
		},
		isUser(){
			return this.$store.state.userModule.user && 
						 this.$store.state.userModule.user.role == 'USER' ||
						 this.$store.state.userModule.user.role == 'MEMBER'
		}

  },
}
</script>

<style></style>
